import { Routes, Route } from "react-router-dom";
import BarcodeGenerator from "./Barcode";
import Home from "./Home";

function App() {
  return (
    <Routes>
      <Route>
        <Route path="/generate/:value" element={<BarcodeGenerator />} />
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );
}

export default App;
