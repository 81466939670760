import Barcode from "react-barcode";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const BarcodeGenerator = () => {
  const routeParams = useParams();

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("img/png");
        const pdf = new jsPDF("l", "mm", [32, 62]);
        pdf.addImage(imgData, "PNG", 0, 0, 62, 32);

        //window.location(pdf.output("bloburl"));
        pdf.output("save", "barcode_" + routeParams.value + ".pdf");
        /* return {
          printerId: "73033846",
          title: routeParams.value,
          contentType: "pdf_base64",
          content: pdf.output("datauristring").split(",")[1],
          source:
            process.env.REACT_APP_NAME + " " + process.env.REACT_APP_VERSION,
        }; */
      })
      .then((payload) => {
        /*         axios.post("https://api.printnode.com/printjobs", payload, {
          headers: {
            Authorization:
              "Basic " + base64_encode(process.env.REACT_APP_PRINT_NODE_API),
          },
        }); */
      });
  };

  useEffect(() => {
    printDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div
        id="divToPrint"
        style={{
          paddingLeft: 10,
          paddingTop: 15,
          width: "62mm",
          height: "32mm",
        }}
      >
        <div style={{ fontFamily: "monospace" }}>
          Hufiec ZHP Warszawa-Żoliborz
        </div>
        <Barcode height={60} value={routeParams.value} />
        <div>
          <button onClick={() => printDocument()}>Send to PrintNode</button>
        </div>
      </div>
    </div>
  );
};

export default BarcodeGenerator;
