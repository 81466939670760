const Home = () => {
  return (
    <div style={{ padding: 10 }}>
      <p>
        <strong>
          {process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION}
        </strong>
      </p>
      <p>Generator kodów kreskowych dla Hufca ZHP Warszawa-Żoliborz.</p>
      <p>A barcode generator for Hufiec ZHP Warszawa-Żoliborz.</p>
      <p>
        <a href="https://olczak.tech">OLCZAK.TECH</a> &copy; 2024 -{" "}
        {new Date().getFullYear()}
      </p>
    </div>
  );
};
export default Home;
